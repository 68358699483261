import { useId, useState } from 'react';
import { templates as templates0, vv } from './conjucacao_data'
import './ConjugaisonApp.css';

class Template {
    constructor(parent, suffix, percentage_sum, percentage_sum_accum, count, template, conjugations) {
        this.parent = parent;
        this.suffix = suffix;
        this.percentage_sum = percentage_sum;
        this.percentage_sum_accum = percentage_sum_accum;
        this.count = count;
        this.template = template;
        this.conjugations = conjugations;
        this.verbs = [];
    }
}

class Verb {
    constructor(infinitive, template) {
        this.infinitive = infinitive;
        this.template = template;
    }
}

class Conjugation {
    constructor(name, rules) {
        this.name = name;
        this.rules = rules;
    }
}

class ConjugationRule {
    constructor(deleteCount, value) {
        this.deleteCount = deleteCount;
        this.value = value;
    }

    getPrefix(infinitive) {
        return infinitive.substring(0, infinitive.length - this.deleteCount);
    }
}

class ConjugationRuleCache {
    static cache = {};

    static get(deleteCount, value) {
        const key = `${deleteCount}:${value}`;
        return this.cache[key] || (this.cache[key] = new ConjugationRule(deleteCount, value));
    }
}

function createConjugation(name, rules) {
    const ruleInstances = rules.map(rule => ConjugationRuleCache.get(rule.delete, rule.value));
    return new Conjugation(name, ruleInstances);
}

function createTemplate(obj) {
    const conjugations = {};
    Object.entries(obj.conjugations).forEach(([name, rules]) => conjugations[name] = createConjugation(name, rules));
    return new Template(obj.parent, obj.suffix, obj.percentage_sum, obj.percentage_sum_accum, obj.count, obj.template, conjugations);
}

function transformToTemplates(templates) {
    const templateInstances = templates.map(templateObj => createTemplate(templateObj));

    const suffixDict = {};
    templateInstances.forEach(template => suffixDict[template.suffix] = template);
    templateInstances.forEach(template => template.parent = suffixDict[template.parent] || null);

    return templateInstances;
}

const modeTenses = {
    "infinitive_present": 1,
    "indicative_present": 6,
    "indicative_imperfect": 6,
    "indicative_future": 6,
    "indicative_simple_past": 6,
    "conditional_present": 6,
    "subjunctive_present": 6,
    "subjunctive_imperfect": 6,
    "imperative_present": 3, //2, 4, 5 //tu, nous, vous
    "participle_present": 1, //Le participe présent se construit en ajoutant le suffixe ‑ant au radical du verbe de la 1re personne du pluriel de l'indicatif présent
    "participle_past": 4
};
const modeTensesFr = [
	"Infinitif - Présent",
	// "Indicatif - Présent",
	// "Indicatif - Imparfait",
	// "Indicatif - Futur simple",
	// "Indicatif - Passé simple",
	"Présent",
	"Imparfait",
	"Futur simple",
	"Passé simple",
	"Conditionnel - Présent",
	"Subjonctif - Présent",
	"Subjonctif - Imparfait",
	"Impératif - Présent",
	"Participe - Présent",
	"Participe - Passé",
];
Object.keys(modeTenses).map((modeTense, i) => modeTenses[modeTense] = {
    id: modeTense,
    name: modeTensesFr[i],
    numeroPessoaGeneros: [...Array(modeTenses[modeTense]).keys()].map(i => `${modeTense}_${+i + 1}`),
    checked: false,
});

const personalPronoms = {
    "1": ["je"],
    "2": ["tu"],
    "3": ["il", "elle", "on"],
    "4": ["nous"],
    "5": ["vous"],
    "6": ["ils", "elles"],
};

const persons = { "1": "1er singulier", "2": "2e singulier", "3": "3e singulier", "4": "1er pluriel", "5": "2e pluriel", "6": "3e pluriel" };
Object.entries(persons).map(([id, name]) => persons[id] = {
    id: id,
    name: name,
    pronoms: personalPronoms[id],
    checked: true,
});

function contraction(w1, w2) {
    let text = `${w1} ${w2}`
    text = text.replace(/(?<=(.*)?j)e (?=[aeéèiou])/, "'")
    return text.replace(/(?<=[' ]).*/, "");
}

const random_index = (length) => Math.max(0, Math.ceil(length * Math.random() - 1));
const random_item = (array) => array[random_index(array.length)];

const templates = transformToTemplates(templates0);
const templatesDict = {};
templates.forEach(template => templatesDict[template.template] = template);

vv.forEach(([template, templateVerbs]) => {
    template = templatesDict[template]
    templateVerbs.split(", ").forEach((templateVerb) => {
        template.verbs.push(new Verb(templateVerb, template));
    })
})


const verbs = [];
Object.values(templatesDict).forEach((template) => {
    verbs.push(template.verbs[0]);
});
verbs.sort((a, b) => b.template.percentage_sum - a.template.percentage_sum)


function DictionaryLink({ word, content }) {
    return <a href={`https://dictionnaire.lerobert.com/definition/${encodeURIComponent(word)}`} target="_blank" rel="noopener noreferrer">{content}</a>;
}

function Phrase({ modeTense, conjugationColumn, verb }) {
    const rules = verb.template.conjugations[conjugationColumn].rules;
    const is6 = modeTense.numeroPessoaGeneros.length === 6;
    const pronome = random_item(persons[conjugationColumn.replace(/.*_/, "")].pronoms)
    const phrase = <>{
        rules.length ? rules.map((rule, index) => <>
            {index > 0 ? <br /> : <></>}
            <span>{is6 ? contraction(pronome, rule.getPrefix(verb.infinitive) + rule.value) : ""}</span>
            <span style={rule.deleteCount > 2 ? {"backgroundColor": "#FFAAAA66"} : {}}>{rule.getPrefix(verb.infinitive)}</span>
            <span className="suffix">{rule.value}</span>
        </>) : "\xA0"
    }</>
    const a = <DictionaryLink word={verb.infinitive} content={phrase} />;

    return modeTense.id === "infinitive_present" ? a : phrase
};

function CheckLabel({item, onChange, defaultChecked}) {
    const id = useId()
    return (
        <div key={item.id}>
            <input type="checkbox" id={id} value={item.id} onChange={onChange} defaultChecked={defaultChecked} />
            <label htmlFor={id}>{item.name}</label>
        </div>
    )
}

function ConjugaisonApp() {
    const [modeTenses2, setModeTenses2] = useState([...Object.values(modeTenses).filter(t => t.checked)]);
    const [persons2, setPersons2] = useState([...Object.values(persons).filter(t => t.checked)]);
    const [showLeftColumns, setShowLeftColumns] = useState(false);

    const onCheckTense = (item, event) => {
        item.checked = event.target.checked
        setModeTenses2([...Object.values(modeTenses).filter(t => t.checked)]);
    }

    const onCheckPerson = (item, event) => {
        item.checked = event.target.checked
        setPersons2([...Object.values(persons).filter(t => t.checked)]);
    }

    const getPerson = (conjugationColumn) => {
        return persons2.filter(p => p.id === conjugationColumn.replace(/.*_/, ""))[0] || null
    }

    return (<>
        <div className="main">
            <div className="left_side">
                {[{
                    id: "show_left_columns",
                    name: "Show left cloumns",
                }].map(item => <CheckLabel key={item.id} item={item} onChange={e => { setShowLeftColumns(!showLeftColumns) }} defaultChecked={showLeftColumns} />)}
                {Object.values(modeTenses).map(item => <CheckLabel key={item.id} item={item} onChange={e => { onCheckTense(item, e) }} defaultChecked={item.checked} />)}
                {Object.values(persons).map(item => <CheckLabel key={item.id} item={item} onChange={e => { onCheckPerson(item, e) }} defaultChecked={item.checked} />)}
            </div>
            <div className="right_side">
                <table className="conjugation-container">
                    <thead>
                        <tr>
                            {showLeftColumns && <>
                                <th rowSpan={2} className="right">row</th>
                                <th rowSpan={2} className="right">usage</th>
                                <th rowSpan={2} className="right">count</th>
                                <th rowSpan={2} className="right">parent</th>
                                <th rowSpan={2} className="right">suffix</th>
                                <th rowSpan={2} className="right">verb</th>
                            </>}
                            {modeTenses2.map((modeTense) =>
                                <th key={modeTense.id} colSpan={modeTense.numeroPessoaGeneros.length === 6 ? persons2.length : modeTense.numeroPessoaGeneros.length}>{modeTense.name}</th>
                            )}
                        </tr>
                        <tr>
                            {modeTenses2.map(modeTense =>
                                modeTense.numeroPessoaGeneros
                                .filter((conjugationColumn) => modeTense.numeroPessoaGeneros.length !== 6 || getPerson(conjugationColumn))
                                .map((conjugationColumn) =>
                                    <th key={conjugationColumn}>{
                                        modeTense.numeroPessoaGeneros.length === 6 ? getPerson(conjugationColumn).name : "\xA0"
                                    }</th>
                                )
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {verbs.map((verb, verb_index) => (
                            <tr key={verb.infinitive}>
                                {showLeftColumns && <>
                                    <td className="right">{verb_index + 1}</td>
                                    <td className="right">&nbsp;{(Math.round(verb.template.percentage_sum * 10000) / 100 + "0").replace(/(?<=(^|[0.]))0/g, "_").replace(/(?<=.{4}).*/, "")}%</td>
                                    <td className="right">{verb.template.count}</td>
                                    <td className="right">{verb.template.parent?.suffix || "\xA0"}</td>
                                    <td className="right">{verb.template.suffix}</td>
                                    <td className="right"><DictionaryLink word={verb.infinitive} content={verb.infinitive} /></td>
                                </>}
                                {modeTenses2.map((modeTense, conjugation_index) =>
                                    modeTense.numeroPessoaGeneros
                                    .filter((conjugationColumn) => modeTense.numeroPessoaGeneros.length !== 6 || getPerson(conjugationColumn))
                                    .map((conjugationColumn) => (
                                        <td className={[conjugation_index % 2 === 0 ? "even" : "odd", "right"].join(" ")} key={verb.infinitive + "_" + modeTense.id + "_" + conjugationColumn}>
                                            <Phrase verb={verb} conjugationColumn={conjugationColumn} modeTense={modeTense} />
                                        </td>
                                    ))
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    </>
    )
}

export default ConjugaisonApp;