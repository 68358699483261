export class MediaRecorderService {
    constructor() {
        this.selectedDeviceId = null;
        this.audioInputDevices = null;
        this.supportedMimeTypes = null;
        this.mediaRecorder = null;
        this.onLoadListeners = [];
        this.onStartStopListeners = [];
        this.onRecordFinishListeners = [];
    }

    async requestPermission() {
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    loadSupportedMimeTypes() {
        if (!this.supportedMimeTypes) {
            //https://stackoverflow.com/questions/41739837/all-mime-types-supported-by-mediarecorder-in-firefox-and-chrome
            const CONTAINERS = ["webm", "ogg"]
            const CODECS = ["opus", "vorbis", null]
            this.supportedMimeTypes = CODECS.flatMap(codec =>
                CONTAINERS.flatMap(ext =>
                    [`audio/${ext}${codec ? `;codecs=${codec}` : ""}`]
                )
            ).filter(MediaRecorder.isTypeSupported);
        }
    }

    async loadAudioInputDevices() {
        if (!this.audioInputDevices) {
            //Load available microphones
            this.audioInputDevices = (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === "audioinput");
            if (this.audioInputDevices.length) {
                this.selectedDeviceId = this.audioInputDevices[0].deviceId
            }
            this.onLoadListeners.forEach(listener => listener(this.audioInputDevices))
        }
    }

    isRecording() {
        return this.mediaRecorder != null;
    }

    async start() {
        if (! await this.requestPermission()){
            alert("Allow the use of your microphone so you can record audios.");
            return;
        }
        this.loadSupportedMimeTypes();
        await this.loadAudioInputDevices();

        const stream = await navigator.mediaDevices.getUserMedia({ audio: true, deviceId: { exact: this.selectedDeviceId } });

        if (this.isRecording()) {
            alert("There is a record in progress. Please, stop the record to continue.");
            return;
        }
        this.onStartStopListeners.forEach(listener => listener("prestart"));
        const mediaRecorder = new MediaRecorder(stream, { mimeType: this.supportedMimeTypes[0] || null });
        mediaRecorder.onerror = (event) => console.error(event);
        mediaRecorder.onstart = (event) => this.onStartStopListeners.forEach(listener => listener("start"));
        mediaRecorder.start();
        this.mediaRecorder = mediaRecorder;
    }

    async stop() {
        const mediaRecorder = this.mediaRecorder;
        if (mediaRecorder) {
            this.mediaRecorder = null;
            mediaRecorder.onstop = (event) => this.onStartStopListeners.forEach(listener => listener("stop"));
            mediaRecorder.ondataavailable = (event) => this.onRecordFinishListeners.forEach(listener => listener(event));
            mediaRecorder.stop();
        }
    }

    async recordAudio(maxDuration) {
        await this.stop();
        await this.start();
        return await new Promise((resolve, reject) => {
            this.mediaRecorder.addEventListener("dataavailable", resolve);
            if (maxDuration) {
                setTimeout(async () => this.mediaRecorder && await this.stop(), maxDuration *  1000)
            }
        });
    }
}
