import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import ConjugaisonApp from './ConjugaisonApp'
import { LANGUAGE_URL } from './application';
import { useState, useEffect } from 'react';
import { RecordButton, AudioInputSelect } from './record';
import { Text, Phrase } from './model/text';
import { TextTag } from './text';
import { TextTyping } from './text_typing';
import { playAudio } from './audio';
import { Language, Model, Speaker } from './piper';
import { MediaRecorderService } from './service/record';

// Data

const languages = [
  new Language({ code: "fr_FR", family: "fr", region: "FR", name_native: "Français", name_english: "French", country_english: "France" }),
  new Language({ code: "en_US", family: "en", region: "US", name_native: "English", name_english: "English", country_english: "United States" }),
  new Language({ code: "en_GB", family: "en", region: "GB", name_native: "English", name_english: "English", country_english: "Great Britain" }),
  new Language({ code: "es_ES", family: "es", region: "ES", name_native: "Español", name_english: "Spanish", country_english: "Spain" }),
  new Language({ code: "es_MX", family: "es", region: "MX", name_native: "Español", name_english: "Spanish", country_english: "Mexico" }),
  new Language({ code: "pt_BR", family: "pt", region: "BR", name_native: "Português", name_english: "Portuguese", country_english: "Brazil" }),
  new Language({ code: "pt_PT", family: "pt", region: "PT", name_native: "Português", name_english: "Portuguese", country_english: "Portugal" }),
];
for (const language of languages) {
  Language.by_code.set(language.code, language);
}

let models = [
  new Model({ id: "fr_FR-siwis-medium", language: Language.by_code.get("fr_FR"), speaker_id_map: [] }), //9
  new Model({ id: "fr_FR-tom-medium", language: Language.by_code.get("fr_FR"), speaker_id_map: [] }), // 8
  // new Model({ id: "fr_FR-upmc-medium", language: Language.by_code.get("fr_FR"), speaker_id_map: ["jessica", "pierre"] }), // 7
  // new Model({ id: "fr_FR-gilles-low", language: Language.by_code.get("fr_FR"), speaker_id_map: [] }), // 5
  // new Model({id: "fr_FR-mls-medium", language: Language.by_code.get("fr_FR"), speaker_id_map: ["1840", "3698", "123", "1474", "12709", "7423", "9242", "8778", "3060", "4512", "6249", "12541", "13634", "10065", "6128", "5232", "5764", "12713", "12823", "6070", "12501", "9121", "1649", "2776", "11772", "5612", "11822", "1590", "5525", "10827", "1243", "13142", "62", "13177", "10620", "8102", "8582", "11875", "7239", "9854", "7377", "10082", "12512", "1329", "2506", "6856", "10058", "103", "14", "6381", "1664", "11954", "66", "1127", "3270", "13611", "13658", "12968", "1989", "12981", "7193", "6348", "7679", "2284", "3182", "3503", "2033", "2771", "7614", "125", "3204", "5595", "5553", "694", "1624", "1887", "2926", "7150", "3190", "3344", "4699", "1798", "1745", "5077", "753", "52", "4174", "4018", "12899", "1844", "4396", "1817", "2155", "2946", "4336", "4609", "1977", "10957", "204", "4650", "5295", "5968", "4744", "2825", "9804", "707", "30", "115", "5840", "2587", "2607", "2544", "28", "27", "177", "112", "94", "2596", "3595", "7032", "7848", "11247", "7439", "2904", "6362"]}), // 3-7
  // new Model({id: "fr_FR-mls_1840-low", language: Language.by_code.get("fr_FR"), speaker_id_map: []}), // 3
  //
  new Model({ id: "en_US-lessac-medium", language: Language.by_code.get("en_US"), speaker_id_map: [] }),
  new Model({ id: "en_US-hfc_female-medium", language: Language.by_code.get("en_US"), speaker_id_map: [] }), //9
  new Model({ id: "en_US-hfc_male-medium", language: Language.by_code.get("en_US"), speaker_id_map: [] }), //8
  new Model({ id: "en_US-joe-medium", language: Language.by_code.get("en_US"), speaker_id_map: [] }), //9
  //
  new Model({ id: "en_GB-cori-high", language: Language.by_code.get("en_GB"), speaker_id_map: [] }), //10
  // new Model({ id: "en_GB-alan-medium", language: Language.by_code.get("en_GB"), speaker_id_map: [] }), //4
  new Model({ id: "en_GB-alba-medium", language: Language.by_code.get("en_GB"), speaker_id_map: [] }), //9
  // new Model({ id: "en_GB-aru-medium", language: Language.by_code.get("en_GB"), speaker_id_map: ["03", "06", "10", "01", "09", "08", "11", "05", "12", "02", "07", "04"] }), //7
  // new Model({ id: "en_GB-northern_english_male-medium", language: Language.by_code.get("en_GB"), speaker_id_map: [] }), // 6
  // new Model({ id: "en_GB-semaine-medium", language: Language.by_code.get("en_GB"), speaker_id_map: ["prudence", "spike", "obadiah", "poppy"] }), // 6
  new Model({ id: "en_GB-vctk-medium", language: Language.by_code.get("en_GB"), speaker_id_map: ["p239", "p236", "p264", "p250", "p259", "p247", "p261", "p263", "p283", "p286", "p274", "p276", "p270", "p281", "p277", "p231", "p271", "p238", "p257", "p273", "p284", "p329", "p361", "p287", "p360", "p374", "p376", "p310", "p304", "p334", "p340", "p323", "p347", "p330", "p308", "p314", "p317", "p339", "p311", "p294", "p305", "p266", "p335", "p318", "p351", "p333", "p313", "p316", "p244", "p307", "p363", "p336", "p297", "p312", "p267", "p275", "p295", "p258", "p288", "p301", "p232", "p292", "p272", "p280", "p278", "p341", "p268", "p298", "p299", "p279", "p285", "p326", "p300", "s5", "p230", "p345", "p254", "p269", "p293", "p252", "p262", "p243", "p227", "p343", "p255", "p229", "p240", "p248", "p253", "p233", "p228", "p282", "p251", "p246", "p234", "p226", "p260", "p245", "p241", "p303", "p265", "p306", "p237", "p249", "p256", "p302", "p364", "p225", "p362"] }), // 8
  //
  new Model({ id: "pt_BR-faber-medium", language: Language.by_code.get("pt_BR"), speaker_id_map: [] }),
  // new Model({ id: "pt_BR-edresson-low", language: Language.by_code.get("pt_BR"), speaker_id_map: [] }), // 4
  // new Model({ id: "pt_PT-tugao-medium", language: Language.by_code.get("pt_PT"), speaker_id_map: [] }), // new Model({ id: "pt_PT-tugão-medium", language: Language.by_code.get("pt_PT"), speaker_id_map: [] }),
  //
  new Model({ id: "es_ES-davefx-medium", language: Language.by_code.get("es_ES"), speaker_id_map: [] }),
  new Model({ id: "es_ES-sharvard-medium", language: Language.by_code.get("es_ES"), speaker_id_map: ["M", "F"] }),
  //
  new Model({ id: "es_MX-claude-high", language: Language.by_code.get("es_MX"), speaker_id_map: [] }),
  //new Model({ id: "es_MX-ald-medium", language: Language.by_code.get("es_MX"), speaker_id_map: [] }),
];

for (const model of models) {
  const speaker_id_map = model.speaker_id_map.length ? model.speaker_id_map : [null];
  for (const i in speaker_id_map) {
    const name = speaker_id_map[i] || null
    const speaker = new Speaker({ index: name !== null ? i : null, name, model });
    model.speaker_by_name.set(name, speaker)
  }
  model.language.models.push(model)
}


//

async function sleep(timeout) {
  return new Promise((resolve, reject) => setTimeout(resolve, timeout))
}

function asyncAwait(promise, timeout = null) {
  return setTimeout(async () => {
    try {
      await promise
    } catch (error) {
      // alert(error.message);
      console.error(error);
    }
  }, timeout);
}

async function fetchOk(resource, options, errorMessage) {
  if (typeof (options) === "string") {
    errorMessage = options;
    options = {}
  }
  const response = await fetch(resource, options);
  if (!response.ok) {
    const error = new Error(errorMessage);
    error.response = response;
    throw error;
  }
  return response;
}

async function getText(path) {
  let response = await fetchOk(`${LANGUAGE_URL}/term/text?path=` + encodeURIComponent(path), "This text is unavailable at this moment. Please, try again later.")
  return await response.text();
}

const onPastText = (textValue) => {
  const parts = window.location.hash.split(/(^#|&)text=[^&]*/)
  window.location.hash = (parts[0] ? parts[0] + "&" : "#") + "text=" + encodeURIComponent(textValue) + (parts[2] || "");
}

/////

const textToWav = (() => {
  async function textToWav(speaker, text) {
    let response = await fetchOk(
      `${LANGUAGE_URL}/tts?` +
      `model=` + encodeURIComponent(speaker.model.id) +
      (speaker.index !== null ? "&speaker=" + encodeURIComponent(speaker.index) : "") +
      "&text=" + encodeURIComponent(text),
      "Text to speach is unavailable at this moment. Please, try again later."
    );
    let wav = new Blob([await response.arrayBuffer()], { type: "audio/x-wav" });
    return wav;
  }

  const cache = {};
  return async function (speaker, text) {
    const key = `${speaker.model.id}|${speaker.index}|${text}`;
    let value = cache[key] || textToWav(speaker, text);
    if (value instanceof Promise) {
      value = await value;
      cache[key] = value
    }
    return value;
  }
})();


let iteratePhrases_lastestCall = null;
class AbortError extends Error {
  constructor(message) {
    super(message);
    this.name = this.constructor.name;
  }
}
async function iteratePhrases(phrases, startPhrase, repeat, onPhrase) {
  const thisCall = iteratePhrases_lastestCall = {}
  const andCheck = async (promise) => {
    const result = await promise
    if (thisCall !== iteratePhrases_lastestCall) {
      throw new AbortError();
    }
    return result;
  }

  do { //Repeat loop
    for (let phrase of phrases) {
      // Start the text or paragraph from the desired phrase.
      if (startPhrase && (startPhrase !== phrase || (startPhrase = null))) {
        continue;
      }

      try {
        let ok = await andCheck(onPhrase(phrase, andCheck));
        if (!ok) {
          return;
        }
      } catch (error) {
        if (error instanceof AbortError) {
          return;
        }
        throw error
      }
    }
  } while (repeat());
}

function scrollIntoViewIfNotVisible(refference, target) {
  if (target.getBoundingClientRect().bottom > refference.getBoundingClientRect().bottom) {
    target.scrollIntoView(false);
  }

  if (target.getBoundingClientRect().top < refference.getBoundingClientRect().top) {
    target.scrollIntoView();
  }
}

async function playPhrase(speaker, phrase, andCheck) {
  let audioDuration = null;
  const onevent = async (event) => {
    phrase.terms.forEach(term => {
      const termElement = document.getElementById(term.id)
      if (!termElement) return;
      termElement.style.backgroundColor = (event.type === "play" ? "green" : "")
      scrollIntoViewIfNotVisible(document.getElementById("center_panel"), termElement);
    });
    if (event.type === "loadedmetadata") {
      audioDuration = event.target.duration;
    }
  };

  let wav = await andCheck(textToWav(speaker, phrase.value));

  let audioEnded = await playAudio({
    src: URL.createObjectURL(wav),
    onplay: onevent,
    onended: onevent,
    onabort: onevent,
    onloadedmetadata: onevent,
  });
  return { audioEnded, audioDuration };
}

async function playPhraseAndSave(language, speaker, phrase, andCheck) {
  const { audioEnded, audioDuration } = await playPhrase(speaker, phrase, andCheck);
  if (audioEnded) {
    saveLearning(language, "lr", phrase.value, "");
  }
  return { audioEnded, audioDuration };
}

function getPhrasesToPlay(textSize, term) {
  let phrases;
  if (textSize === "text") {
    phrases = [...term.phrase.paragraph.text.getPhrases()];
  } else if (textSize === "paragraph") {
    phrases = [...term.phrase.paragraph.phrases];
  } else if (textSize === "phrase") {
    phrases = [term.phrase];
  } else if (textSize === "3_words") {
    let words = 3;
    let phrase = new Phrase(null, "", null);
    let wordsFound = 0;
    for (let i = term.phrase.terms.indexOf(term); i < term.phrase.terms.length; i++) {
      let term2 = term.phrase.terms[i];
      phrase.value += term2.value;
      phrase.terms.push(term2);
      if (term2.isWord) {
        if (++wordsFound === words) {
          break;
        }
      }
    }
    phrases = [phrase];
  } else if (textSize === "word") {
    let phrase = new Phrase(null, "", null);
    phrase.value += term.value;
    phrase.terms.push(term);
    phrases = [phrase];
  }
  return phrases;
}

async function saveLearning(language, actions, phrase, writing) {
  fetchOk(`${LANGUAGE_URL}/learning/save?` + new URLSearchParams({ language: language.code, actions, phrase, writing }).toString(), "Unable to save the learning.");
}

async function saveAudio(language, actions, phrase, blob) {
  var fd = new FormData();
  fd.append('file', new File([blob], "audio", { type: blob.type }));

  fetchOk(`${LANGUAGE_URL}/learning/saveAudio?` + new URLSearchParams({ language, actions, phrase }).toString(), {
    method: 'POST',
    body: fd
  }, "Unable to save the audio.");
}

async function openTranslation(term) {
  //let url = `https://dictionnaire.lerobert.com/definition/${encodeURIComponent(term.value)}`; // Google french dictionary
  //let url = `https://context.reverso.net/translation/french-english/${encodeURIComponent(term.value)}`;
  let url = `https://www.collinsdictionary.com/dictionary/french-english/${encodeURIComponent(term.value)}`;
  //let url = `https://en.wiktionary.org/wiki/${encodeURIComponent(term.value)}#French`; // Probably the most extensive dictionary
  //let url = `https://dictionary.cambridge.org/dictionary/french-english/${encodeURIComponent(term.value)}`; //avons not found
  window.open(url, '_blank').focus();
}

function variable(itemName) {
  const defaultValues = {
    textSize: "text",
    repeat: false,
    recordAudio: true,
    typeText: true,
  }
  const currentValues = {}
  return {
    subscribe: (o, name) => {
      Object.defineProperty(o, name || itemName, {
        get() {
          let value;
          if (itemName in currentValues) {
            value = currentValues[itemName]
          } else {
            value = window.localStorage.getItem(itemName)
            value = value !== null ? JSON.parse(value) : defaultValues[itemName]
            currentValues[itemName] = value
          }
          // console.log("get:" +itemName + " = " + value)
          return value;
        },
        set(value) {
          window.localStorage.setItem(itemName, JSON.stringify(value))
          // console.log("set:" +itemName + " = " + value)
          currentValues[itemName] = value
        }
      });
    }
  }
}

function Home() {
  const [text, setText] = useState(null);
  const mediaRecorderService = new MediaRecorderService()
  let textTyping = null;

  const changePhonome = (e) => {
    (async () => {
      var french_phonemes = ["/i/", "/e/", "/ɛ/", "/a/", "/ɑ/", "/o/", "/ɔ/", "/u/", "/y/", "/ø/", "/œ/", "/ə/", "/ɛ̃/", "/ɑ̃/", "/ɔ̃/", "/œ̃/", "/p/", "/b/", "/t/", "/d/", "/k/", "/g/", "/m/", "/n/", "/ɲ/", "/ŋ/", "/f/", "/v/", "/s/", "/z/", "/ʃ/", "/ʒ/", "/l/", "/ʁ/", "/j/", "/ɥ/", "/w/"]
      //var phoneme_id = Math.min(Math.floor(Math.random() * french_phonemes.length), french_phonemes.length);

      let language_code = decodeURIComponent(window.location.hash.split(/[#&]language=/)[1]?.split(/&/)[0] || "");
      var phonemeStr = decodeURIComponent(window.location.hash.split(/[#&]phoneme=/)[1]?.split(/&/)[0] || "");
      var textPath = decodeURIComponent(window.location.hash.split(/[#&]text=/)[1]?.split(/&/)[0] || "");
      let model_id = decodeURIComponent(window.location.hash.split(/[#&]model=/)[1]?.split(/&/)[0] || "");
      let language = language_code || model_id.substring(0, 5);

      if (phonemeStr.match(/^\d+$/)) {
        window.location.hash = "phoneme=" + french_phonemes[phonemeStr];
        return
      }
      var phoneme_id = french_phonemes.indexOf(phonemeStr);

      var textValue;
      if (phoneme_id >= 0) {
        //var textPath = `/fr_FR/phoneme/phoneme_${phoneme_id}.txt`;
        //var textPath = `/fr_FR/phoneme/phoneme_30_words_${phoneme_id}.txt`;
        textPath = `/fr_FR/phoneme/phoneme_50_words_${phoneme_id}.txt`;

        var phoneme = french_phonemes[phoneme_id];
        textValue = phoneme + ".\n\n" + await getText(textPath);

      } else if (textPath) {
        if (textPath.match(/^\/[^\n\t]*[^\s;.]$/)) {
          textValue = await getText(textPath);
        } else {
          textValue = textPath;
        }
      } else {
        textValue = await getText(`/${language || "fr_FR"}/text1.txt`);
      }

      //Simple text for test
      //textValue = "Le ami." + textValue.substring(0, 0);

      textValue = textValue.replace(/’/g, "'"); // Normalize

      setText(new Text({ id: +new Date(), value: textValue, textSize: variable("textSize"), repeat: variable("repeat"), recordAudio: variable("recordAudio"), typeText: variable("typeText") }));
    })().catch(e => console.error(e));
  }

  const pasteContent = async (event) => {
    event.preventDefault();

    // let textHtml = event.clipboardData.getData("text/html");
    // if(textHtml) {
    //   console.log(textHtml);
    // }

    let textValue = event.clipboardData.getData("text");
    if (!textValue) {
      return;
    }

    onPastText(textValue);
  }

  useEffect(() => {
    window.addEventListener("hashchange", changePhonome, false);
    window.addEventListener("paste", pasteContent, false);
    changePhonome();
    return () => {
      window.removeEventListener("hashchange", changePhonome);
      window.removeEventListener("paste", pasteContent);
    }
  }, []);

  const onCLickTerm = (event, term) => {
    if (text.textSize === "translate") {
      openTranslation(term);
    } else {
      let language_code = decodeURIComponent(window.location.hash.split(/[#&]language=/)[1]?.split(/&/)[0] || "");
      let model_id = decodeURIComponent(window.location.hash.split(/[#&]model=/)[1]?.split(/&/)[0] || "");
      let speaker_index = decodeURIComponent(window.location.hash.split(/[#&]speaker=/)[1]?.split(/&/)[0] || "");

      if (language_code.indexOf("_") === -1) {
        const codes = [...Language.by_code.keys()];
        language_code = codes.find(_ => _.startsWith(language_code + "_" + language_code.toUpperCase())) || codes.find(_ => _.startsWith(language_code + "_")) || language_code
      }

      let language = Language.by_code.get(language_code || "fr_FR");

      let selectedSpeakers;
      if (!model_id) {
        selectedSpeakers = language.speakers
      } else {
        const model = models.find(_ => _.id === model_id)
        language = model.language
        selectedSpeakers = model.speakers
        if (speaker_index) {
          selectedSpeakers = [selectedSpeakers[speaker_index]]
        }
      }

      term.phrase.speakerIndex ||= 0;
      const getSpeaker = ((index) => {
        // return () => selectedSpeakers[Math.max(0, Math.ceil(Math.random() * selectedSpeakers.length) - 1)]
        // let index = Math.max(0, Math.ceil(Math.random() * selectedSpeakers.length) - 1);
        return () => selectedSpeakers[term.phrase.speakerIndex = index = (index + 1) % selectedSpeakers.length];
      })(term.phrase.speakerIndex += 1);
      const phrasesToPlay = getPhrasesToPlay(text.textSize, term);
      const startPhrase = phrasesToPlay.indexOf(term.phrase) >= 0 ? term.phrase : null;
      let nextSpeaker = null;

      const onPhrase = async (phrase, andCheck) => {
        const speaker = nextSpeaker || getSpeaker();
        nextSpeaker = getSpeaker()

        { //Preload next phrase audio
          const nextPhrase = phrasesToPlay[phrasesToPlay.indexOf(phrase) + 1];
          if (nextPhrase) {
            asyncAwait(textToWav(nextSpeaker, nextPhrase.value), 100)
          }
        }

        // Close previous typing
        textTyping && await andCheck(textTyping.waitComplete(""));

        // Play phrase
        const { audioEnded, audioDuration } = await andCheck(playPhraseAndSave(language, speaker, phrase, andCheck));
        if (!audioEnded) {
          return false;
        }

        //Record, play record and phrase again
        if (text.recordAudio) {
          // Play phrase again
          // if (!(await andCheck(playPhraseAndSave(language, speaker, phrase, andCheck))).audioEnded) return false;

          const recordDuration = audioDuration ? audioDuration + 0.5 : null
          const event = await andCheck(mediaRecorderService.recordAudio(recordDuration));

          asyncAwait(saveAudio(language.family, "s", phrase.value, event.data))

          await andCheck(playAudio({ src: URL.createObjectURL(event.data) }));

          // // Play phrase again
          // if (!(await andCheck(playPhraseAndSave(language, speaker, phrase, andCheck))).audioEnded) return false;
        }

        //Wait typing finish
        if (text.typeText) {
          // // Play phrase again
          // playPhraseAndSave(language, speaker, phrase, andCheck);

          // Play phrase over and over again until typing finishes
          let playFraseOverAndOverAgain = true;
          let typedPhrase = "";
          let typedPhraseTime;
          let typedPhraseWordI = 0;
          asyncAwait((async () => {
            let playedTimes = 0;
            let maxTimesPerRemainingPhrase = 1;
            let previousWordI = -1;
            const stop = () => !playFraseOverAndOverAgain || typedPhrase === phrase.value
            while (!stop()) {
              // Wait at least 3 seconds after last typing.
              typedPhraseTime = +new Date();

              for (; ;) {
                if (previousWordI !== (previousWordI = typedPhraseWordI)) {
                  playedTimes = 0;
                }

                let timeout;
                if (playedTimes >= maxTimesPerRemainingPhrase) {
                  timeout = 1E9;
                } else {
                  let wait = playedTimes ? 6000 : 3000;
                  let nextPlayTime = typedPhraseTime + wait;
                  timeout = nextPlayTime - new Date();
                  if (timeout <= 0) break;
                }

                await sleep(Math.min(200, timeout))
                if (stop()) return;
              }

              let aa = typedPhrase;

              // Remove word being typed.
              if (phrase.value.substring(aa.length).match(/^[^\s\t].*/)) {
                aa = aa.replace(/[\s\t]*[^\s\t]*[\s\t]*$/, "");
              }
              // Remove last typed word.
              aa = aa.replace(/[\s\t]*[^\s\t]*[\s\t]*$/, "");

              let remainingPhrase = phrase.value.substring(aa.length);
              remainingPhrase = remainingPhrase.replace(/^[\s\t]+/, "") // Remove whote space from the beginning.
              if (!remainingPhrase) return;

              // Keep first 5 words
              remainingPhrase = remainingPhrase.replace(/^[\s\t]*([^\s\t]+([\s\t]+[^\s\t]+){0,4}).*/, "$1")

              //TODO: Improve it using original text and adding status to phrases.
              const text2 = new Text({ id: +new Date(), value: remainingPhrase, textSize: variable("textSize"), repeat: variable("repeat"), recordAudio: variable("recordAudio"), typeText: variable("typeText") })
              remainingPhrase = [...text2.getPhrases()][0];

              await playPhraseAndSave(language, getSpeaker(), remainingPhrase, andCheck);
              playedTimes++;
            }
          })());

          try {
            !textTyping && (textTyping = new TextTyping(document.getElementById("eSrcText")));
            let oncharcomplete = (character) => {
              typedPhraseTime = +new Date();
              typedPhrase += character;
              typedPhraseWordI = phrase.value.substring(0, typedPhrase.length + 1).split(/[\s\t]+/).length - 1;
            };
            const writtenPhrase = await andCheck(textTyping.waitComplete(phrase.value, oncharcomplete));
            saveLearning(language, "w", phrase.value, writtenPhrase);
          } finally {
            playFraseOverAndOverAgain = false;
          }
        }

        // Time to speak.
        if (!text.recordAudio && !text.typeText) {
          // await andCheck(sleep(audioDuration * 1000 + 500))
        }

        return true;
      };

      asyncAwait(iteratePhrases(phrasesToPlay, startPhrase, () => text.repeat, onPhrase));
    }
  }

  const onChangeTextSize = (e) => {
    text.textSize = e.target.value;
  }

  const onChangeRepeat = (e) => {
    text.repeat = !text.repeat;
  }

  const onChangeRecordAudio = (e) => {
    text.recordAudio = !text.recordAudio;
  }

  const onChangeTypeText = (e) => {
    text.typeText = !text.typeText;
  }

  return text && (
    <div className="App">
      <div id="center_panel" className="center_panel"><TextTag key={text.id} text={text} onCLickTerm={onCLickTerm} /></div>
      {/* <div id="debug"></div> */}
      <div className="writing_panel" id="eSrcText" style={{ display: "none" }} contentEditable="false" spellCheck="false"></div>
      <div className="bottom_panel">
        <form>
          <div className="switch-field">
            {[
              { id: "text", name: "Text" },
              { id: "paragraph", name: "Paragraph" },
              { id: "phrase", name: "Phrase" },
              { id: "3_words", name: "3 Words" },
              { id: "word", name: "Word" },
            ].map((item) => <div key={item.id}>
              <input type="radio" id={"radio-" + item.id} name="switch-text-size" value={item.id} onChange={onChangeTextSize} defaultChecked={item.id === text.textSize} />
              <label htmlFor={"radio-" + item.id}>{item.name}</label>
            </div>)}
            &nbsp;&nbsp;
            {[
              { id: "translate", name: "Translate" },
            ].map((item) => <div key={item.id}>
              <input type="radio" id={"radio-" + item.id} name="switch-text-size" value={item.id} onChange={onChangeTextSize} defaultChecked={item.id === text.textSize} />
              <label htmlFor={"radio-" + item.id}>{item.name}</label>
            </div>)}
            &nbsp;&nbsp;
            {[
              { id: "repeat", name: "Repeat" }
            ].map((item) => <div key={item.id}>
              <input type="checkbox" id={"checkbox-" + item.id} name="switch-repeat" value={item.id} onChange={onChangeRepeat} defaultChecked={text.repeat} />
              <label htmlFor={"checkbox-" + item.id}>{item.name}</label>
            </div>)}
            &nbsp;&nbsp;
            {[
              { id: "recordAudio", name: "Speak" }
            ].map((item) => <div key={item.id}>
              <input type="checkbox" id={"checkbox-" + item.id} name="switch-recordAudio" value={item.id} onChange={onChangeRecordAudio} defaultChecked={text.recordAudio} />
              <label htmlFor={"checkbox-" + item.id}>{item.name}</label>
            </div>)}
            &nbsp;&nbsp;
            {[
              { id: "typeText", name: "Write" }
            ].map((item) => <div key={item.id}>
              <input type="checkbox" id={"checkbox-" + item.id} name="switch-typeText" value={item.id} onChange={onChangeTypeText} defaultChecked={text.typeText} />
              <label htmlFor={"checkbox-" + item.id}>{item.name}</label>
            </div>)}
            &nbsp;
            <div>
              <RecordButton mediaRecorderService={mediaRecorderService} />
            </div>
            <div>
              <AudioInputSelect mediaRecorderService={mediaRecorderService} />
            </div>
            &nbsp;
            <div>
              <textarea className="textarea_past_text" rows={1} cols={17} onFocus={(event) => event.currentTarget.value = ""} onBlur={(event) => event.currentTarget.value = "Past a text here."} onInput={() => false} onChange={(event) => onPastText(event.currentTarget.value)} defaultValue={"Past a text here."} />
            </div>
          </div>
        </form>
      </div>
      <audio id="audio" className="audio" controls={true} autoPlay={true}></audio>
    </div>
  );
}

function App() {
  return (
    <Router>
      <div>
        {/* Navigation Links */}
        { false &&
          <nav>
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/conjugaison">Conjugaison</Link>
              </li>
            </ul>
          </nav>
        }

        {/* Route Definitions */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/conjugaison" element={<ConjugaisonApp />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
