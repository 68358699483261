export async function playAudio({ src, onplay, onended, onabort, onloadedmetadata }) {
    return new Promise((resolve, reject) => {
        const audio = document.getElementById("audio");
        audio.onloadedmetadata = onloadedmetadata;
        audio.onerror = async (event) => {
            const error = new Error("Audio error.");
            error.event = event;
            reject(error)
        };
        audio.onplay = async (event) => {
            onplay && await onplay(event);
            audio.onended = async (event) => {
                audio.onabort = null;
                onended && await onended(event);
                resolve(true);
            };
            audio.onabort = async (event) => {
                onabort && await onabort(event);
                resolve(false);
            };
        };
        audio.src = src;
    });
}
