export class Text {
    constructor({id, value, textSize, repeat, recordAudio, typeText}) {
        this.id = id;
        this.value = value;
        this.paragraphs = value.split(/\s*[\r\n]\s*/).map((value, i) => (new Paragraph(`${id}_${i}`, value, this)));
        textSize.subscribe(this)
        repeat.subscribe(this)
        recordAudio.subscribe(this)
        typeText.subscribe(this)
    }

    getTerms() {
        const terms = [];
        for (let paragraph of this.paragraphs) {
            if (terms.length) {
                terms.push(new Term(`${terms[terms.length - 1].id}_0`, "\n", null));
            }
            terms.push(...paragraph.getTerms());
        }
        return terms;
    }

    *getPhrases() {
        for (let paragraph of this.paragraphs) {
            yield* paragraph.phrases;
        }
    }
}

export class Paragraph {
    constructor(id, value, text) {
        this.id = id;
        this.value = value;
        this.text = text;
        this.phrases = value.split(/(?<=[.?!])\s+|\t+/).map((value, i) => (new Phrase(`${id}_${i}`, value, this)));
    }

    getTerms() {
        const terms = [];
        for (let phrase of this.phrases) {
            if (terms.length) {
                terms.push(new Term(`${terms[terms.length - 1].id}_0`, " ", phrase));
            }
            terms.push(...phrase.terms);
        }
        return terms;
    }
}

export class Phrase {
    constructor(id, value, paragraph) {
        this.id = id;
        this.value = value;
        this.paragraph = paragraph;
        this.terms = value.split(/(\s+|[.?!,;:])/).filter(v => v).map((value, i) => (new Term(`${id}_${i}`, value, this)));
    }
}

export class Term {
    constructor(id, value, phrase) {
        this.id = id;
        this.value = value;
        this.phrase = phrase;
        this.isWord = value.match(/^[-'0-9A-Za-zÀ-ÖØ-öø-ÿ]+$/);
        this.isWhiteSpace = value.match(/^\s+$/);
        this.hasNewLine = value.match(/[\r\n]/);
    }
}
