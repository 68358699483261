import { useEffect, useState } from 'react';

export function RecordButton({ mediaRecorderService }) {
    const [recording, setRecording] = useState(false);

    useEffect(() => {
        mediaRecorderService.onStartStopListeners.push((event) => {
            if (event === "prestart") {
                setRecording(true);
            } else if (event === "stop") {
                setRecording(false);
            }
        });
    }, [mediaRecorderService])

    const toggleRecording = (event) => {
        event.preventDefault();
        recording ? mediaRecorderService.stop() : mediaRecorderService.start();
    };

    return <button className={recording ? "record_button recording" : "record_button"} onClick={toggleRecording}></button>;
};

export function AudioInputSelect({ mediaRecorderService }) {
    const [devices, setDevices] = useState([]);

    useEffect(() => {
        mediaRecorderService.onLoadListeners.push(setDevices);
    }, [mediaRecorderService])

    const onChange = async (event) => {
        mediaRecorderService.selectedDeviceId = event.currentTarget.options[event.currentTarget.selectedIndex].value;
    }

    return <select className="audio_input_select" onChange={onChange}>{
        devices.map(device => <option key={device.deviceId} value={device.deviceId}>{device.label}</option>)
    }</select>;
};