
export class Language {
    constructor({ code, family, region, name_native, name_english, country_english }) {
        this.code = code;
        this.family = family;
        this.region = region;
        this.name_native = name_native;
        this.name_english = name_english;
        this.country_english = country_english;
        this.models = [];
        Language.by_code.set(code, this)
    }

    get speakers() {
        const speakers = [];
        for (const model of this.models) {
            for (const speaker of model.speakers) {
                speakers.push(speaker)
            }
        }
        return speakers
    }

    static by_code = new Map();
}
export class Model {
    constructor({ id, language, speaker_id_map }) {
        this.id = id;
        this.language = language;
        this.speaker_id_map = speaker_id_map;
        this.speaker_by_name = new Map();
        Model.by_id[id] = this
    }

    get speakers() {
        return [...this.speaker_by_name.values()];
    }

    static by_id = {};
}
export class Speaker {
    constructor({ index, name, model }) {
        this.index = index;
        this.name = name;
        this.model = model;
    }
}
