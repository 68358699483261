// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
    margin: 0;
    height: 100%;
    overflow: hidden;
}
.main {
    display: flex;
    height: 100vh;
    width: 100vw;
}
.left_side {
    width: 200px;
    min-width: 200px;
    height: 100vh;
    background-color: #f0f0f0;
}
.right_side {
    flex-grow: 1;
    height: 100vh;
    overflow-y: scroll;
    /* background-color: #e0e0e0; */
}

.conjugation-container {
    margin: auto;
    font-weight: bold;
    white-space: nowrap;
    font-size: 14px;
}

table.transpose { border-collapse: collapse; }
table.transpose tr { display: block; float: left; }
table.transpose th, table.transpose td { display: block; border: 1px solid black; }

.suffix {
    color: red;
}

.right{
    text-align: right;
}

.even{
    background-color: #DDDDDD;
}
.odd{
    background-color: #F2F2F2;
}
`, "",{"version":3,"sources":["webpack://./src/ConjugaisonApp.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,YAAY;IACZ,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,aAAa;IACb,YAAY;AAChB;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B;AACA;IACI,YAAY;IACZ,aAAa;IACb,kBAAkB;IAClB,+BAA+B;AACnC;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,mBAAmB;IACnB,eAAe;AACnB;;AAEA,kBAAkB,yBAAyB,EAAE;AAC7C,qBAAqB,cAAc,EAAE,WAAW,EAAE;AAClD,yCAAyC,cAAc,EAAE,uBAAuB,EAAE;;AAElF;IACI,UAAU;AACd;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B","sourcesContent":["html, body {\r\n    margin: 0;\r\n    height: 100%;\r\n    overflow: hidden;\r\n}\r\n.main {\r\n    display: flex;\r\n    height: 100vh;\r\n    width: 100vw;\r\n}\r\n.left_side {\r\n    width: 200px;\r\n    min-width: 200px;\r\n    height: 100vh;\r\n    background-color: #f0f0f0;\r\n}\r\n.right_side {\r\n    flex-grow: 1;\r\n    height: 100vh;\r\n    overflow-y: scroll;\r\n    /* background-color: #e0e0e0; */\r\n}\r\n\r\n.conjugation-container {\r\n    margin: auto;\r\n    font-weight: bold;\r\n    white-space: nowrap;\r\n    font-size: 14px;\r\n}\r\n\r\ntable.transpose { border-collapse: collapse; }\r\ntable.transpose tr { display: block; float: left; }\r\ntable.transpose th, table.transpose td { display: block; border: 1px solid black; }\r\n\r\n.suffix {\r\n    color: red;\r\n}\r\n\r\n.right{\r\n    text-align: right;\r\n}\r\n\r\n.even{\r\n    background-color: #DDDDDD;\r\n}\r\n.odd{\r\n    background-color: #F2F2F2;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
