export function TermTag({ term, onClick }) {
    return <div id={term.id} className="term" tabIndex="-1" onClick={e => onClick(e, term)}>{term.value}</div>
}

export function EmptyLineTag() {
    return <div style={{ width: '100%', height: '15px' }}></div>
}

export function TextTag({ text, onCLickTerm }) {
    return (
        <div className="text">{
            text.getTerms().map(term => term.hasNewLine ? <EmptyLineTag key={term.id} /> : <TermTag key={term.id} term={term} onClick={onCLickTerm} />)
        }</div>
    )
}
