export function TextTyping(eSrcText) {
    // function log(text) {
    //     var d2 = document.createElement("PRE");
    //     d2.innerText = `${text} : ${encodeURIComponent(text)}\n`
    //     document.getElementById("debug").appendChild(d2);
    // }

    function getTextNodesIn(node) {
        var textNodes = [];
        if (node.nodeType === 3) {
            textNodes.push(node);
        } else {
            var children = node.childNodes;
            for (var i = 0, len = children.length; i < len; ++i) {
                textNodes.push.apply(textNodes, getTextNodesIn(children[i]));
            }
        }
        return textNodes;
    }

    var setSelectionRange_currentArguments = null;
    function setSelectionRange(el, start, end) {
        setSelectionRange_currentArguments = [el, start, end];

        var range = new Range();
        range.selectNodeContents(el);
        var textNodes = getTextNodesIn(el);
        var foundStart = false;
        var charCount = 0, endCharCount;

        for (var i = 0; i < textNodes.length; i++) {
            var textNode = textNodes[i];
            endCharCount = charCount + textNode.length;
            if (!foundStart && start >= charCount && (start < endCharCount || (start === endCharCount && i + 1 <= textNodes.length))) {
                range.setStart(textNode, start - charCount);
                foundStart = true;
            }
            if (foundStart && end <= endCharCount) {
                range.setEnd(textNode, end - charCount);
                break;
            }
            charCount = endCharCount;
        }

        var sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }


    var Contador = function () {
        this.concluidos = 0;
        this.acertos = 0;
        this.erros = 0;
    };

    var nonBreakingHiffen = String.fromCharCode(8209); //Non-breaking Hyphen
    var nbsp = String.fromCharCode(160);
    var defaultColor = "#FFFFFF00"; // Transparent text
    var rightColor = "white";
    var fixedColor = "orange";
    var wrongColor = "red";
    var caretColor = "#FFFFFF";

    var converter = function (element, array) {
        if (array === undefined) {
            array = [];
        }

        var text = element.innerText.replace(/[\s\u00A0]+/g, " ").replace(/(^ | $)/g, "");
        element.innerHTML = "";

        for (var textI = 0; textI < text.length; textI++) {
            var character = text.charAt(textI);
            var textContent = character;
            if (character === " ") {
                textContent = nbsp;
            } else if (character === "-") {
                textContent = nonBreakingHiffen;
            }
            var divChar = document.createElement("DIV");
            divChar.style.display = character !== " "? "inline" : "inline-block";
            divChar.style.position = "relative";
            divChar.character = character;
            divChar.textContent0 = textContent === nonBreakingHiffen ? "-" : textContent;
            divChar.textContent = textContent;
            element.appendChild(divChar, element);
            array.push(divChar);
            divChar.style.color = defaultColor;
            divChar.style.caretColor = caretColor;
            divChar.acertou = null;
            if (divChar.textContent0 !== nbsp) {
                var encontrou = false;
                var anterior = divChar;
                while ((anterior = anterior.previousSibling) && anterior.offsetTop !== divChar.offsetTop && anterior.textContent0 !== nbsp) {
                    encontrou = true;
                }
                if (encontrou && anterior && anterior.textContent0 === nbsp) {
                    anterior.style.display = "inline-block";
                }
            }
        }
        return array;
    };


    var divChars;
    var contador;

    eSrcText.onmousedown = function (e) {
        if (setSelectionRange_currentArguments) {
            setSelectionRange.apply(null, setSelectionRange_currentArguments);
        }
        return false;
    };

    eSrcText.onkeydown = function (e) {
        var which = e.which || e.keyCode;
        //console.log("which: " + which);
        if (e.key.length > 1) {
            if (which === 9) { //Tab
                skipChar();
            } else if (which === 8 && divError) { //Backspace
                setError(null);
            }
            if (which !== 116 && which !== 123) { //F5, F12
                return false;
            }
        }
    };

    var _this = this;

    eSrcText.onbeforeinput = function (e) {
        var character = e.data === " " ? nbsp : e.data;
        var divChar = window.getSelection().focusNode.parentNode;
        var divCharsI = divChars.indexOf(divChar);
        if (divCharsI > -1 && !divError) {
            if (divCharsI > 0 || divChars[divCharsI].concluido) {
                divCharsI++;
            }
            if (divCharsI < divChars.length) {
                divChar = divChars[divCharsI];
                if (divChar.textContent0 === character) {
                    if (divChar.acertou !== false) {
                        divChar.acertou = true;
                        contador.acertos++;
                    }
                    contador.concluidos++;

                    divChar.style.color = divChar.acertou ? rightColor : fixedColor;
                    divChar.concluido = true;

                    _this.oncharcomplete(divChar.textContent0)
                    if (contador.concluidos === divChars.length) {
                        var result = "";
                        for (var d of divChars) {
                            result += d.acertou ? d.character : "_";
                        }
                        _this.oncomplete(result);
                    }
                } else {
                    if (divChar.acertou !== false) {
                        if (divChar.acertou === true) {
                            contador.acertos--;
                        }
                        divChar.acertou = false;
                        contador.erros++;
                    }

                    setError(divChar, character);
                }

                setSelectionRange(divChar, 1, 1);

                //Fix edition bug in mobile
                var textContent = divChar.textContent;
                setTimeout(() => {
                    //log(divChar.textContent)
                    divChar.textContent = textContent;
                    setSelectionRange(divChar, 1, 1);
                });
            }
        }
        return false;
    };

    var divError = null;
    var setError = function (divChar, character) {
        if (divError) {
            divError.textContent = divError.textContent0;
            divError.style.color = defaultColor;
            divError.style.caretColor = caretColor;
            var divCharsI = divChars.indexOf(divError);
            if (--divCharsI >= 0) {
                var divChar2 = divChars[divCharsI];
                setSelectionRange(divChar2, 1, 1);
            } else {
                setSelectionRange(divError, 0, 0);
            }
            divError = null;
        }
        if (divChar) {
            divError = divChar;
            divError.textContent = character === nbsp ? "_" : character;
            divError.style.color = wrongColor;
        }
    };

    var skipChar = function () {
        if (divError) {
            setError(null);
        }
        var divChar = window.getSelection().focusNode.parentNode;
        var divCharsI = divChars.indexOf(divChar);
        if (divCharsI > -1 && !divError) {
            if (divCharsI > 0 || divChars[divCharsI].concluido) {
                divCharsI++;
            }
            if (divCharsI < divChars.length) {
                divChar = divChars[divCharsI];
                eSrcText.onbeforeinput({
                    data: divChar.textContent0
                });
                if (divChar.acertou === true || divChar.acertou === false) {
                    if (divChar.acertou) {
                        contador.acertos--;
                    } else {
                        if (!divChar.textContent0.match(/[a-zA-Z0-9]/)) {
                            contador.erros--;
                        }
                    }
                }
            }
        }
    };

    this.setText = function (text) {
        eSrcText.innerHTML = text;
        eSrcText.style.display = text !== "" ? null : "none";
        eSrcText.contentEditable = "true";
        divError = null;
        setSelectionRange_currentArguments = null;
        divChars = converter(eSrcText);
        contador = new Contador();
        setSelectionRange(eSrcText, 0, 0);
    }

    this.oncomplete = () => {};
    this.oncharcomplete = () => {};

    var cancelWait = () => {};
    this.waitComplete = async (text, oncharcomplete) => {
        return await new Promise((resolve, reject) => {
            cancelWait(null);
            cancelWait = resolve;
            _this.setText(text);
            _this.oncomplete = (result) => {
                _this.setText("");
                resolve(result);
            };
            _this.oncharcomplete = oncharcomplete
            if (text === "") {
                resolve("");
            }
        });
    };
};
